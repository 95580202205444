// ** Next Imports

// ** React Imports
import { useState, useEffect } from "react";

// ** FA Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

// ** Data Imports
import { REVIEWS } from "../../helpers/DATA";

interface Props {
  ignoreHead?: boolean;
}

// ** Image Imports
const Reviews = ({ ignoreHead }: Props) => {
  const [reviewIndex, setReviewIndex] = useState<number>(0);

  useEffect(() => {
    const reviewsTimer = setInterval(() => {
      setReviewIndex((reviewIndex + 1) % REVIEWS.length);
    }, 5000);

    return () => clearInterval(reviewsTimer);
  });

  return (
    <section className="pad-top-60 pad-bot-60 reviews-section">
      <div className="container">
        <div className="block-element">
          {ignoreHead !== true && (
            <div className="block-element text-center m-b-30">
              <div className="sec-head4">
                <h3 className="col-black">We make Internet Service Easy.</h3>
                <h3 className="col-blue1">
                  That&apos;s why Customers Love Us.
                </h3>
              </div>
            </div>
          )}

          <div className="block-element custom-slider1 arrows-1 home-reviews">
            {REVIEWS.map((review, rIndex) => (
              <div
                className="review-box animate__animated animate__slideInRight"
                key={rIndex}
                style={{
                  display: reviewIndex === rIndex ? "block" : "none",
                }}
              >
                <div className="review-title">
                  {review.img}
                  <h5>{review.name}</h5>
                  <h6>{review.from}</h6>
                  <p>
                    <FontAwesomeIcon icon={faStar} color="#f8bd06" />
                    <FontAwesomeIcon icon={faStar} color="#f8bd06" />
                    <FontAwesomeIcon icon={faStar} color="#f8bd06" />
                    <FontAwesomeIcon icon={faStar} color="#f8bd06" />
                    <FontAwesomeIcon icon={faStar} color="#f8bd06" />
                  </p>
                </div>
                <div className="review-desc">
                  <p>{review.review}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
