// ** Next Imports
import Link from "next/link";
import Image from "next/image";

// ** FA Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// ** Img Imports
import needIcon1Img from "../../public/images/need-icon1.jpg";
import needIcon2Img from "../../public/images/need-icon2.jpg";
import needIcon3Img from "../../public/images/need-icon3.jpg";

import featureIcon1Img from "../../public/images/feature-icon1.jpg";
import featureIcon2Img from "../../public/images/feature-icon2.jpg";
import featureIcon3Img from "../../public/images/feature-icon3.jpg";
import featureIcon4Img from "../../public/images/feature-icon4.jpg";

import internetIcon1Img from "../../public/images/internet-icon1.jpg";
import internetIcon2Img from "../../public/images/internet-icon2.jpg";
import internetIcon3Img from "../../public/images/internet-icon3.jpg";

import partner1Img from "../../public/images/partner-1.png";
import partner3Img from "../../public/images/partner-5.png";
import partner4Img from "../../public/images/partner-4.png";

import deatil3Img from "../../public/images/detail-3.jpg";
import map1Img from "../../public/images/map-1.png";

import cnbImage from "../../public/images/cnb.png";

// ** File Imports
import Layout, { SEOHead } from "../layouts/default";
import Reviews from "../components/shared/reviews";

export default function Home() {
  return (
    <Layout>
      <SEOHead title="Top Rated Internet Provider by address | Easy Internet Now">
        <meta
          name="description"
          content="If you are looking for high speed internet from top rated internet service providers in USA, EIN provides you with cheapest plans and best customer service."
        ></meta>
        <meta
          name="keywords"
          content="best internet provider, internet providers by address, cheapest internet providers, top rated internet provider, internet providers near me"
        />
      </SEOHead>

      <section
        className="pad-top-60 pad-bot-60 banner-sec1"
        style={{
          backgroundImage: "url(/images/banner-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="block-element">
            <div className="banner-text">
              <h1 className="col-white">
                Business & Home Internet should be Easy
              </h1>
              <h5 className="col-white">
                High-speed Internet. Blazing Fast Speed. Flat Price. No Hassle.
              </h5>
              <Link href="/check-availability" className="custom-btn5">
                Check Availability
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="pad-top-80 pad-bot-40">
        <div className="container">
          <div className="block-element m-b-50">
            <div className="sec-head7">
              <h3 className="col-black">Everything you need in internet</h3>
              <p className="col-grey1">
                Easy plans, all-in price guarantee, no bundling, no credit check
                and 5-star service from EIN. Because the last thing you should
                be worried about is your home internet.
              </p>
              <Link href="/check-availability" className="custom-btn1">
                Check Availability
              </Link>
            </div>
          </div>
          <div className="block-element">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-sm-4 col-12">
                <div className="need-box">
                  <Image
                    className="img-fluid"
                    placeholder="blur"
                    src={needIcon1Img}
                    alt={"icon"}
                  />
                  <h3 className="col-black">Hassle Free Internet</h3>
                  <p className="col-grey1">
                    The connection you need without a contract or credit check!
                    No hidden fees, no bundling or equipment charges. With
                    flat-rate plans you always know what your monthly payment
                    will be with EIN.{" "}
                  </p>
                  <Link href="/plans" className="custom-btn1">
                    View Plans
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-4 col-12">
                <div className="need-box">
                  <Image
                    className="img-fluid"
                    placeholder="blur"
                    src={needIcon2Img}
                    alt={"icon"}
                  />
                  <h3 className="col-black">99.9% Internet Uptime</h3>
                  <p className="col-grey1">
                    Whether you&apos;re relying on your connection to run your
                    business, stream a movie or connect with the people you
                    love, the last thing you need to worry about is your
                    internet slowing you down.
                  </p>
                  <Link href="/about-us" className="custom-btn1">
                    Learn More About EIN
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-4 col-12">
                <div className="need-box">
                  <Image
                    className="img-fluid"
                    placeholder="blur"
                    src={needIcon3Img}
                    alt={"icon"}
                  />
                  <h3 className="col-black">Amazing Customer Support</h3>
                  <p className="col-grey1">
                    EIN is known for 5-star customer support. Our team provides
                    text, Facebook messagener, email and phone support. However
                    you need to reach us, we are here to help.
                  </p>
                  <Link href="/support" className="custom-btn1">
                    Customer Support
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blue2 pad-top-80 pad-bot-20">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-sm-6 col-12">
              <div className="features-box">
                <Image
                  className="img-fluid"
                  placeholder="blur"
                  src={featureIcon1Img}
                  alt={"icon"}
                />
                <h3 className="col-white">5/5</h3>
                <h5 className="col-white">CUSTOMER RATING</h5>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 col-12">
              <div className="features-box">
                <Image
                  className="img-fluid"
                  placeholder="blur"
                  src={featureIcon2Img}
                  alt={"icon"}
                />
                <h3 className="col-white">15</h3>
                <h5 className="col-white">AMAZING PLANS</h5>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 col-12">
              <div className="features-box">
                <Image
                  className="img-fluid"
                  placeholder="blur"
                  src={featureIcon3Img}
                  alt={"icon"}
                />
                <h3 className="col-white">21</h3>
                <h5 className="col-white">STATES SERVICED</h5>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 col-12">
              <div className="features-box">
                <Image
                  className="img-fluid"
                  placeholder="blur"
                  src={featureIcon4Img}
                  alt={"icon"}
                />
                <h3 className="col-white">5000</h3>
                <h5 className="col-white">MBPS SPEED</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pad-top-60 pad-bot-60">
        <div className="container">
          <div className="row equal-col custom-border3">
            <div className="col-md-6 col-lg-6 col-sm-12 col-12 order-lg-1 order-md-1 order-sm-1 order-1">
              <div className="detail-image">
                <Image
                  className="img-fluid"
                  placeholder="blur"
                  src={deatil3Img}
                  alt={"icon"}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 col-12  order-lg-2 order-md-2 order-sm-2 order-2">
              <div className="detail-text">
                <h3 className="col-black m-b-20">
                  Connect all your devices and access high speed Internet with
                  ease
                </h3>
                <h4 className="col-black m-b-20">
                  Every EIN customer gets a free Wi-Fi modem to connect
                  everything in their home or business!
                </h4>
                <p className="col-grey1">
                  During your installation, your technician will set up your new
                  network and help you understand how to connect devices to your
                  new connection.
                </p>
                <Link href="/check-availability" className="custom-btn2">
                  Check Availability <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
          <div className="row equal-col">
            <div className="col-md-6 col-lg-6 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-1 order-1">
              <div className="detail-image">
                <Image
                  className="img-fluid"
                  placeholder="blur"
                  src={map1Img}
                  alt={"icon"}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 col-12  order-lg-1 order-md-1 order-sm-2 order-2">
              <div className="detail-text">
                <h3 className="col-black m-b-20"> Service for Millions </h3>
                <h4 className="col-black m-b-20">
                  EIN provides high-speed Internet Service to millions of
                  locations across the country!
                </h4>
                <p className="col-grey1">
                  With service in 21 states, chances are we cover your home or
                  business.
                </p>
                <Link href="/check-availability" className="custom-btn2">
                  Check Availability <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blue1 pad-top-60 pad-bot-40">
        <div className="container">
          <div className="row equal-col">
            <div className="col-md-4 col-lg-4 col-sm-12 col-12">
              <div className="sec-head6">
                <h5 className="col-white">What can you do with</h5>
                <h2 className="col-white ">Super Fast Internet</h2>
              </div>
            </div>
            <div className="col-md-8 col-lg-8 col-sm-12 col-12">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-4 col-12">
                  <div className="super-fast-box">
                    <Image
                      className="img-fluid"
                      placeholder="blur"
                      src={internetIcon1Img}
                      alt={"icon"}
                    />

                    <h4 className="col-white">Unlimited Streaming</h4>
                    <p className="col-white">
                      All EIN plans are UNLIMITED. That means you can stream
                      your favorite shows and music on your WIFI network without
                      worrying about data caps or throttling!
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 col-12">
                  <div className="super-fast-box">
                    <Image
                      className="img-fluid"
                      placeholder="blur"
                      src={internetIcon2Img}
                      alt={"icon"}
                    />
                    <h4 className="col-white">Lag-Free Gaming</h4>
                    <p className="col-white">
                      Never lose a game again because your connection was
                      lagging. Keep your trigger finger fast and your ping low
                      with EIN.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 col-12">
                  <div className="super-fast-box">
                    <Image
                      className="img-fluid"
                      placeholder="blur"
                      src={internetIcon3Img}
                      alt={"icon"}
                    />
                    <h4 className="col-white">Share Your Connection</h4>
                    <p className="col-white">
                      With speeds up-to 1Gbps your whole family or business can
                      run without worrying about slow speeds, buffering, or
                      interrupting each other!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Reviews />

      <section className="pad-top-40 pad-bot-40 bg-silver1">
        <div className="container">
          <div className="block-element partner-logos">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <Image
                  placeholder="blur"
                  src={partner1Img}
                  alt={"Partner 1"}
                  width={344}
                  height={65}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <Image
                  placeholder="blur"
                  src={partner3Img}
                  alt={"Partner 3"}
                  width={344}
                  height={65}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <Image
                  placeholder="blur"
                  src={partner4Img}
                  alt={"Partner 4"}
                  width={344}
                  height={65}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <Link href="/carbonneutral">
                  <Image
                    src={cnbImage}
                    placeholder="blur"
                    alt="EIN carbon neutral"
                    width={344}
                    height={65}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
